/*
 Nom du fichier : InscriptionParent.css
 fonction: ce fichier représente le css de l'inscription Parent, avec le mode pc, tablette et Téléphone.

 auteur : Thanina adda.
 Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css
*/

/* Main du fichier */
.mainInscriptionParent {


	background-image: url("/src/components/Parent/Inscription/images/image1.jpg");

	background-repeat: no-repeat;
	background-size: 150% 100%;
	background-size: cover;

}

.titreInterfaceParent {
	margin-left: 8%;
}

.custom-size {
	width: 100%;
	height: 100vh;
}

/* Label du fichier */
label {
	font-weight: 600;
}

/* Form area du fichier inscription Parent*/
.form-areaInscriptionParent {

	padding-left: 20px;
	width: 100%;
	height: max-content;
	padding-top: 5%;
	padding-bottom: 16%;
}


/* Sigle-form */
.single-form {
	background-color: #D0D5E5;
	box-shadow:
		-10px 0px 20px -5px rgba(0, 0, 0, 0.2),
		0px 2px 20px -5px rgba(0, 0, 0, 0.5);
}

/* Bloc Gauche de l'inscription Parent */
.leftInscriptionParent {
	filter: contrast(90%) opacity(0.7);
	background-color: white;
	text-align: justify;
	padding: 130px 40px;

}

/* Span du formulaire d'inscription Parent */
.spanFomulaireParent {
	font-size: 14px;
	font-weight: 600;
	margin-top: 10px;
	text-align: center;
	cursor: pointer;
}

/* h3 du formulaire d'inscription Parent */
.leftInscriptionParent h3 {

	font-family: poppins;
	font-weight: 700;
	color: #000;
	text-shadow: 1px 1px #fff;
}

/* h5 du formulaire d'inscription Parent cote droit */
.rightInscriptionParent h5 {
	text-align: center;
}

/* Span du formulaire d'inscription Parent */
.leftInscriptionParent span {
	color: #000;
	text-shadow: 1px 1px #fff;
	font-weight: 600;
	font-size: 18px;

}

/* Bouton Submit inscription Parent */
.btnSubmit {
	display: flex;
	justify-content: center;
}

/* Bloc Droit de l'inscription Parent */
.rightInscriptionParent {
	padding: 0px 100px;
	filter: brightness(90%) opacity(0.9);
	padding-top: 30px;
	padding-bottom: 10px;

}

/* i du Bloc Droit de l'inscription Parent */
.rightInscriptionParent i {
	position: absolute;
	font-size: 80px;
	left: -27px;
	top: 40%;
	color: #fff;
}

/* form-control du projet */
.form-control {
	border: 2px solid #000;
}

/* bouton du bloc Droit de l'inscription Parent */
.rightInscriptionParent button {
	border: none;
	border-radius: 0;
	background: #252525;
	width: 180px;
	color: #fff;
	padding: 15px 0;
	display: inline-block;
	font-size: 16px;
	margin-top: 50px;
	cursor: pointer;
}

.rightInscriptionParent button:hover {
	background-color: #252525;
}



/*Media query pour le mode tablette */
@media (min-width:750px) and (max-width:1000px) {

	.mainInscriptionParent {
		background-image: url("/src/components/Parent/Inscription/images/image1.jpg");
		background-repeat: no-repeat;
		background-size: 110% 140%;
	}

	.form-areaInscriptionParent {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		padding-left: 20px;
	}

	.rightInscriptionParent i {
		top: -52px;
		transform: rotate(90deg);
		left: 50%;
	}

	.leftInscriptionParent {
		padding: 100px 90px;
	}

	.rightInscriptionParent {}

	.titreInterfaceParent {
		margin-left: 0%;
	}

}

/*Media query pour le mode telephone */
@media (max-width:700px) {
	.mainInscriptionParent {
		background-image: url("/src/components/Parent/Inscription/images/image3.jpg");
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.leftInscriptionParent {
		text-align: justify;
		padding: 60px 20px;
	}

	.leftInscriptionParent h3 {
		text-align: center;
	}

	.leftInscriptionParent span {
		font-size: 15px;
	}

	.spanFomulaireParent {
		font-size: 15px;
	}

	.rightInscriptionParent {
		padding: 18px;
	}

	.rightInscriptionParent i {
		top: -52px;
		transform: rotate(90deg);
		left: 46%;
	}

	.rightInscriptionParent button {
		width: 150px;
		padding: 12px 0;
	}

	.form-areaInscriptionParent {
		padding-left: 20px;
	}

	.titreInterfaceParent {
		margin-left: 0%;
	}

}