
/*
 Nom du fichier : InscriptionBenevole.css
 fonction: ce fichier représente le css de l'inscription Bénévole, avec le mode pc, tablette et Téléphone.

 auteur : Thanina adda.
 Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css
*/


/* Main du fichier inscription Bénévole  */

.mainInscriptionBenevole {
	
	background-image: url("/src/components/Benevole/Inscription/images/image4.jpg");
	background-repeat: no-repeat;
	background-size: 110% 110%;
	
  }

  .titreInterfaceBenevole {
  	margin-left: 8%;
  }
  
 /* custom-size inscription Bénévole */
.custom-size {
	width: 100%;
	height: 100vh;
}

/* Label du fichier  inscription Bénévole*/
label {
  font-weight:600;
}


/* Form area du fichier inscription Bénévole*/
.form-areaInscriptionBenevole {
	
	padding-left: 20px;
	width: 100%;
	height:max-content;
	padding-top: 3%;
	padding-bottom: 5%;
	
}

/* Sigle-form inscription Bénévole */
.single-form {
	background-color: #D0D5E5;
	box-shadow: 
	  -10px 0px 20px -5px rgba(0, 0, 0, 0.2), /* ombre portée à gauche avec 0.2 d'opacité */
	   0px 2px 20px -5px rgba(0, 0, 0, 0.5); /* ombre portée standard avec 0.5 d'opacité */
	 
  }

/* Bloc Gauche de l'inscription Bénévole */
.leftInscriptionBenevole {

	filter:contrast(90%) opacity(0.7);
	background-color: white; 
	text-align: justify;
 	padding: 120px 80px;
	
}
/* Texte du bloc gauche de l'inscription Bénévole */
.texteLeftInscriptionBenevole
{
	padding-bottom: 40%;
	padding-top: 30px;
}
/* Span du bloc Gauche de l'inscription Bénévole */
.leftInscriptionBenevole span
{
	
	font-size: 18px;
}

/* Span du Formulaire */
.spanFomulaireBenevole
{
	font-size: 14px;
	font-weight:600;
	padding-top: 10px;
	text-align: center; 
	cursor: pointer;
	
}

/* h3 du formulaire d'inscription Bénévole */
.leftInscriptionBenevole h3 {
	
	padding-top: 10%;
	font-family: poppins;
	font-weight: 700;
	color: #000;
	text-shadow:1px 1px #fff;
}

/* h5 du formulaire d'inscription Bénévole cote droit */
.rightInscriptionBenevole h5 {
	text-align: center;	
}


/* Span du formulaire d'inscription Bénévole */
.leftInscriptionBenevole span {
	color: #000;
	text-shadow:1px 1px #fff;
	font-weight:600;
	
	
}

/* Bouton Submit inscription Bénévole */
.btnSubmit
{
	display: flex;
	justify-content: center;
}

/* Bloc Droit de l'inscription Bénévole */
.rightInscriptionBenevole {
	padding: 0px 100px;
	filter:brightness(90%) opacity(0.9);
	padding-top: 45px;
}

/* i du Bloc Droit de l'inscription Bénévole */
.rightInscriptionBenevole i {
	position: absolute;
	font-size: 80px;
	left: -27px;
	top: 40%;
	color: #fff;
}

/* Bouton du bloc droite de l'inscription Bénévole */
.rightInscriptionBenevole button {
	border: none;
	border-radius: 0;
	background: #252525;
	width: 180px;
	color: #fff;
	padding: 15px ;
	display: inline-block;
	font-size: 16px;
	
	cursor: pointer;
}

.rightInscriptionBenevole button:hover {
	background-color: #252525;
}


/*Media query pour le mode tablette */
@media (min-width:750px) and (max-width:1000px) {
	.form-areaInscriptionBenevole {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		padding-left: 20px;
	  }
	.rightInscriptionBenevole i {
		top: -52px;
		transform: rotate(90deg);
		left: 50%;
	}
	.mainInscriptionBenevole  {
	
		background-repeat: no-repeat;

		background-size: 110% 120%;
	}
	
	.leftInscriptionBenevole {
		
		text-align: justify;
		padding: 100px 90px;		
	}
	.rightInscriptionBenevole {
		
		padding-top: 20px;
	}
	.spanFomulaireBenevole
	{
		margin-bottom: 30px;
	}
	.titreInterfaceBenevole {
		margin-left: 0%;
	}
}


/*Media query pour le mode téléphone  */
@media (max-width:700px) {
	.mainInscriptionBenevole  {
    background-image: url("/src/components/Benevole/Inscription/images/image3.jpg");
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	
	.leftInscriptionBenevole {
		
		text-align: justify;
		padding: 100px 20px;		
	}
	.leftInscriptionBenevole h3 {
		text-align: center;
	
		
	}
	.titreInterfaceBenevole {
		margin-left: 0%;
	}
	.leftInscriptionBenevole span 
	{
		
		font-size: 15px;
	}
	.spanFomulaire
	{
		font-size: 15px;
	}
	
	.rightInscriptionBenevole {
		padding: 15px;
	}

	.rightInscriptionBenevole i {
		top: -52px;
		transform: rotate(90deg);
		left: 46%;
	}

	.rightInscriptionBenevole button {
		width: 150px;
		padding: 12px 0;
	}
	.form-areaInscriptionBenevole {
	
		padding-left: 20px;
	
	}

}