/*
 Nom du fichier : Connexion.css
 Fonction: ce fichier représente le css du fichier Connexion.js avec le mode pc, tablette et Téléphone.

 Auteur : Thanina adda.
 Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css
*/

/* Main du fichier */
.mainMotDePasseOublie {
	background-color: #D9D9D9;
	background-size: 100% 135%;
}
/* custom-size */
.custom-size {
	width: 100%;
	height: 100vh;
}


/* form-area  */
.form-areaMotDePasseOublie {
	padding-left: 20px;
	width: 100%;
	height: auto;
	padding-top: 3%;
	padding-bottom: 10%;	

}

/* single-form */
.single-formMotDePasseOublie {
	
	background-color: #D0D5E5;
	box-shadow:
		-10px 0px 20px -5px rgba(0, 0, 0, 0.2),
		0px 2px 20px -5px rgba(0, 0, 0, 0.5);
}



/* pan du formulaire de Connexion */
.spanFomulaireMotDePasseOublie {
	font-size: 14px;
	font-weight: 600;
	margin-top: 20%;
	text-align: center;
	cursor: pointer;
}


/* Bouton Submit Connexion Utilisateur */
.btnSubmitMotDePasseOublie {
	display: flex;
	justify-content: center;
	padding-top: 20%;
}

/* Bloc Droit */
.rightMotDePasseOublie {
	margin-top: 20px;
	padding: 100px 100px;
	filter: brightness(90%) opacity(0.9);
	padding-top: 10%;
}

/* i du Bloc Droit de l'inscription */
.rightMotDePasseOublie i {
	position: absolute;
	font-size: 80px;
	left: -27px;
	top: 40%;
	color: #fff;
}


/* Bouton Submit Connexion */
.rightMotDePasseOublie button {
	border: none;
	border-radius: 0;
	background: #252525;
	width: 180px;
	color: #fff;
	padding: 15px 0;
	display: inline-block;
	font-size: 16px;
	margin-top: 20px;
	cursor: pointer;
}

.rightMotDePasseOublie button:hover {
	background-color: #252525;
}



/*Media query pour le mode telephone */
@media (min-width:868px) and (max-width:1000px) {
	.form-area {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		padding-left: 20px;
	}

	.rightMotDePasseOublie i {
		top: -52px;
		transform: rotate(90deg);
		left: 50%;
	}


}

@media (max-width:800px) {
	
	.rightMotDePasseOublie {
		padding: 18px;
	}

	.rightMotDePasseOublie i {
		top: -52px;
		transform: rotate(90deg);
		left: 46%;
	}

	.rightMotDePasseOublie button {
		width: 150px;
		padding: 12px 0;
	}

	.form-area {
		padding-left: 20px;
	}

}