/*
 Nom du fichier : ActiviteHalteAllaitement.js
 Fonction: ce fichier représente le css de la page d'inscription aux activités,
 avec le mode pc, tablette et Téléphone.
  
 Auteur : Thanina adda.

*/


/* Bloc Droit de l'inscription Benevole */

.mainActiviteHalte {
	width: 100%;
	height: auto;
	padding: 5%;
	
	background-color: #EEEEEE;
	display: flex;
	flex-direction: row;


}

.single-formActiviteFrancais{
	

	padding-top: 5px;
	
}

.ActiviteGaucheHalte
{
	margin-top: 50px;
	padding-right: 5%;
	cursor: pointer;
}

.ActiviteDroiteHalte {
	margin-top: 50px;
	padding-left: 5%;
	box-shadow:
		-10px 0px 20px -5px rgba(0, 0, 0, 0.1),
		0px 2px 20px -5px rgba(0, 0, 0, 0.3);
		
}

.selected {
	background-color: #FFFFFF;
	color: #3C4E8B;

	border-radius: 50%;
}


.activiteDHalte
{
	margin-top: 50px;
	margin-bottom: 25px;
	margin-left : 20px;
}
 .activiteGHalte{
	margin-top: 35px;
	
} 




.ListeActiviteHalte {
	margin-top: 2px;
	
	
}

.activiteBlock4Halte {

	color: white;
 background-color: #3C4E8B;

}


.activiteBlock4Halte p {

	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}


.ActiviteDroiteHalte {
	padding-bottom: 20px;
	padding-left: 5px;
	background-color: whitesmoke;
	

}

.ActiviteUneHalte {
	

}

.ActiviteDeuxHalte {

	padding-left: 11px;
	padding-right: 10px;
	margin-bottom: 10px;
	
}

.activiteBlock3Halte button {
	padding-top: 10px;
}

p {
	text-align: justify;
}

.iconActivite {
	margin-right: 20px;
}

.activiteBlock1Halte {
	margin: 10px;
}

.activiteBlock2Halte {}

.activiteBlock3Halte {
	margin: 10px;
}

.selected {
	background-color: #FFFFFF;
	color: #3C4E8B;
	border-radius: 50%;
}


.ActiviteTroisHalte span {
	padding: 5%;

}


@media (min-width:868px) and (max-width:1000px) {
	.mainActiviteHalte {
		display: block;
	}
	
	.selected {
		background-color: #FFFFFF;
		color: #3C4E8B;
	
		border-radius: 50%;
	}

}

@media (max-width:860px) {

	.rightInscriptionActivite {}

	.mainActiviteHalte {
		background-color: whitesmoke;
		display: block;
		padding: 1%;
	}

	.ActiviteGaucheHalte {
		
		padding-right: 3%;
		margin-top: 10px;
	}
	.activiteDHalte {
		
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 20px;
	}

	.ActiviteDroiteHalte {
		margin-left: 0px;	
	}

	
	.ListeActiviteHalte {
		margin-left: 20px;
	}

	.ActiviteDeuxHalte {
		padding-top: 10px;
	}

	.ActiviteTroisHalte {
		padding-top: 10px;

	}
	.rightInscriptionActiviteFrancais
	{
		margin-right: 22px;
	}
	
	
}

@media (max-width:500px) {

	.rightInscriptionActivite {}

	.mainActiviteHalte {
		background-color: whitesmoke;
		display: block;
		padding: 1%;

	}

	.ActiviteGaucheHalte {
		
		padding-right: 6%;
		margin-top: 10px;
		
	}
	.activiteDHalte {
		
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 20px;
	}

	.ActiviteDroiteHalte {
		margin-left: 0px;
	}

	
	.ListeActiviteHalte {
		margin-left: 20px;
	}

	.ActiviteDeuxHalte {
		padding-top: 0px;
		padding-left: 0px;
		padding-right: 0px;
	}

	.ActiviteTroisHalte {
		padding-top: 0px;
	}
	
	.rightInscriptionActiviteFrancais
	{
		margin-right: 22px;
	}

}