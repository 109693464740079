/*
 nom du fichier : Chat.module.css
 fonction: ce fichier represente le css de la page chat et chat bénévole

 auteur : maxence mazeau.

*/

.chatContainer {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 100vh;
  -ms-overflow-style: none;
}

.sidebar {
  background-color: #EBF1F6;
  color: black;
  flex: 1;
  padding: 5px;
  border-bottom: solid;
  overflow-y : scroll;
  -ms-overflow-style: none;
}

h2 {
  margin-top : 0 px;
  margin-left : 10 px;
}

.chatWindow {
  flex: 3;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  overflow-y: scroll;
  z-index: 2;
}


.fenetreChat {
  flex: 3;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow-y: scroll;
  z-index: 2;
}

/* Set additional styling options for the columns*/
.column {
  background-color: white;
  border-radius: 10px;
  margin-left: 10px;
  float: left;
  height: 25px;
  width: 40%;
  justify-content: center;
  display: flex; /* add this property */
  text-align: center;
  font-weight: bold;
  }

.row:after {
  content: "";
  display: table;
  clear: both;
  }

.selected {
    background-color: #2787F5 !important;
    color: white;
  }

.info {
  padding: 10px;
  border-radius: 10px;
  margin-bottom : 10px;
  background-color: white;
  text-align: left;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}


.infoActivite {
  padding: 10px;
  border-radius: 10px;
  margin-top : 10px;
  background-color: white;
  text-align: left;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}


.info .compteur {
  position: relative;
  left: 99%;
  width: 30px;
  height: 30px;
  background-color: red;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nom {
  text-align: left;
  font-weight: bold;
}

.description {
  border-radius: 10px;
  text-align: left;;
  padding: 5px;
  overflow: hidden;
}

.temps {
  position: relative;
  bottom: 0;
  left: 55%;
  font-size: 12px;
  margin-left: auto; /* add this property */
}

.date {
  position: relative;
  bottom: 0;
  left: 75%; 
  font-size: 12px;
  margin-left: auto;
}

.parentInfo {
  position: relative;
  bottom: 0;
  left: 1%;
  font-size: 14px;
  font-weight: bold;
  color : #3D488B;
  margin-top: -10px;
  margin-bottom: -20px;
  text-align: left;
  margin-right: auto; /* add this property */
}

.supprimer{
  position : relative;
  bottom: 0;
  left : 1%;
  color : red;
  font-size: 16px;
  font-weight : bold;
  margin-top : -10px;
  margin-bottom : -3px;
}

.parentInfo:hover {
  color : black;
}


.loadMoreButton {
  margin: 5px auto;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  top : 15%;
  border-radius: 5px;
  cursor: pointer;
}

.loadMoreButton:hover {
  background-color: #0069d9;
}


.message {
  display: flex;
  max-width: 100%;
  padding: 5px 16px;
}

.messageSender {
  font-weight: bold;
  text-align: left;
  top: 0;
  left: 0;
  margin-right: 10px;
}

.sent .messageInfo {
  text-align: justify;
  border-radius: 10px;
  padding: 5px;
  color : white;
  min-width: 30%;
  max-width: 60%;
  background-color: #2787F5;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 7px;
  margin-right: 0;
}

.received .messageInfo {
  color : black;
  text-align: left;
  padding: 5px;
  min-width: 30%;
  max-width: 60%;
  border-radius: 10px;
  background-color: #EBF1F6;
  margin-top: 7px;
  margin-left: 0;
  margin-right: auto;
}

.messageTime {
  text-align: right;
  font-size: 12px;
  color: #95A9C9;
  bottom: 0;
  left: 0;
}

.image {
  display: flex;
  max-width: 100%; /* make sure the image width doesn't exceed its container */
  height: auto; /* maintain the image's aspect ratio */
  object-fit: cover; /* ensure the image covers the container without distortion */
}

.buttonCreerChat {
  position: sticky;
  background-color: #2787F5;
  width: 95%;
  margin-left: 30px;
  border: 1px solid black;
  border-radius: 5px;
  color: #fff;
  bottom : 0;
  z-index: 1;
  font-size: 16px;
}

.newMessageForm {
  position: sticky;
  background-color: white;
  width: 100%;
  margin-left: 10px;
  color: #fff;
  border: none;
  bottom : 0;
  border-top: 1px solid black;
  z-index: 1;
 
}

.newMessageForm input[type="text"] {
  flex: 1;
  background-color: #EBF1F6;
  color: black;
  width: 67%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}

.newMessageForm button[type="submit"] {
  background-color: #2787F5;;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 15%;
  text-align: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 16px;
}

.newMessageForm button[type="file"] {
  background-color: #2787F5;;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 15%;
  text-align: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 16px;
}

.newMessageForm button[type="button"] {
  background-color: #2787F5;;
  color: white;
  padding: 10px;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  width: 15%;
  margin-left: 10px;
  font-size: 16px;
}

.menuFiltre {
  position: absolute;
  width: 15%;
  bottom: 100%; 
  right: 16%;
  left: auto; 
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  list-style: none;
  padding: 0;
  margin : auto;
  z-index: 1;
}

.menuFiltre > li {
  margin: 0;
  color : black;
  background-color: white;
}

.menuFiltre > li:hover {
  background-color: lightgray;
}

.menuFiltre > li > button {
  width: 100%;
  height: 100%;
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

  /* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

  /* CSS */
  .column{
    width: 40%;
  }

  .sent .messageInfo{
    min-width: 20%;
    max-width: 45%;
    white-space:normal
  }

  .received .messageInfo{
    width: 45%;
    white-space:normal
  } 
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .chatContainer{
    flex-direction: column;
    width: 100%;
  }

  .fenetreChat {
    width: 100%;
  }
  
  /* CSS */
  .sidebar {
    flex: auto;  
    height: 5%;
    width: 100%;
  }

  .messageForm{
    width: 100%;
  }

  .sent .messageInfo{
    min-width: 45%;
    max-width: 60%;
    white-space:normal
  }

  .received .messageInfo{
    min-width: 45%;
    max-width: 60%;
    white-space:normal
  } 

  .column{
    width: 35%;
  }

  .temps {

    left: 85%;
  }
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  /* CSS */
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  /* CSS */
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  /* CSS */
  .chatContainer{
    flex-direction: column;
    width: 100%;
  }

  .sidebar {
    flex: auto;  
    height: 0%;
    width: 100%;
  }
   .sent .messageInfo{
    min-width: 45%;
    max-width: 90%;
    white-space:normal
  }

  .received .messageInfo{
    min-width: 45%;
    max-width: 90%;
    white-space:normal
  } 

  .newMessageForm input[type="text"] { 
    width: 55%;
  }

  .newMessageForm button[type="submit"] { 
    width: 20%;
  }

  .newMessageForm button[type="file"] { 
    width: 17%;
    margin-left: 5px;
  }
  .newMessageForm button[type="button"] { 
    margin-left: 0px;
    width: 20%;
  }
 

  .column{
    width: 40%;
    margin-left : 20px;
  }

  .newMessageForm{
    width: 100%;
  }

  .menuFiltre{
    right: 12%;
    width: 22%;
  }

  .temps{
    left: 55%;
  }

  .buttonCreerChat{
    width: 87%;
  }



}