/*
 Nom du fichier : ActiviteHalteAllaitementAnglais.js
 Fonction: ce fichier représente le css de la halte allaitement section anglais, avec le mode pc, tablette et Téléphone.
  
 Auteur : Thanina adda.

*/

/* Bloc Droit de l'inscription Benevole */

.mainActiviteHalteA {
	width: 100%;
	height: auto;
	padding: 5%;
	padding-bottom: 14%;
	background-color: #EEEEEE;
	display: flex;
	flex-direction: row;
}
.ActiviteGaucheHalteA
{
	margin-top: 50px;
	padding-right: 5%;
}

.ActiviteDroiteHalteA {
	margin-top: 50px;
	padding-left: 5%;
	box-shadow:
		-10px 0px 20px -5px rgba(0, 0, 0, 0.1),
		0px 2px 20px -5px rgba(0, 0, 0, 0.3);		
}

.selected {
	background-color: #FFFFFF;
	color: #3C4E8B;

	border-radius: 50%;
}
.single-formActiviteAnglais {

	padding-top: 5px;
	
}

.activiteDHalteA
{
	margin-top: 50px;
	margin-bottom: 25px;
	margin-left : 20px;
}
 .activiteGHalteA{
	margin-top: 35px;
	cursor: pointer;
} 


.ListeActiviteHalteA {
	margin-top: 2px;
}

.activiteBlock4HalteA {
	color: white;
 background-color: #3C4E8B;

}


.activiteBlock4HalteA p {

	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}


.ActiviteDroiteHalteA {
	padding-bottom: 20px;
	padding-left: 5px;
	background-color: whitesmoke;

}

.ActiviteUneHalteA {
	

}

.ActiviteDeuxHalteA {

	padding-left: 11px;
	padding-right: 10px;
	margin-bottom: 10px;
	
}

.activiteBlock3HalteA button {
	padding-top: 10px;
}

p {
	text-align: justify;
}

.iconActiviteA {
	margin-right: 20px;
}

.activiteBlock1HalteA {
	margin: 10px;
}

.activiteBlock2HalteA {}

.activiteBlock3HalteA {
	margin: 10px;
}


.selected {
	background-color: #FFFFFF;
	color: #3C4E8B;

	border-radius: 50%;
}

.ActiviteTroisHalteA span {
	padding: 5%;
}
  

@media (min-width:868px) and (max-width:1000px) {
	.mainActiviteHalteA {
		display: block;
	}

	.selected {
		background-color: #FFFFFF;
		color: #3C4E8B;
		border-radius: 50%;
	}
	
}



@media (max-width:860px) {

	.rightInscriptionActiviteA {}

	.mainActiviteHalteA {
		background-color: whitesmoke;
		display: block;
		padding: 1%;
	
	}
	.ActiviteGaucheHalteA {
		margin-top: 10px;
	}
	.activiteDHalteA {
		
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 20px;
	}

	.ActiviteDroiteHalteA {
		margin-left: 0px;	
	}

	
	.ListeActiviteHalteA {
		margin-left: 20px;
	}

	.ActiviteDeuxHalteA {
		padding-top: 10px;
	}

	.ActiviteTroisHalteA {
		padding-top: 10px;
	}

	.rightInscriptionActiviteAnglais
	{
		margin-right: 22px;
	}
	
	
}

@media (max-width:500px) {

	.rightInscriptionActiviteA {}

	.mainActiviteHalteA {
		background-color: whitesmoke;
		display: block;
		padding: 1%;
		

	}
	.ActiviteGaucheHalteA {
		margin-top: 10px;
		padding-right: 6%;
	}
	.activiteDHalteA {
		
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 20px;
	}

	.ActiviteDroiteHalteA {
		margin-left: 0px;
	}

	
	.ListeActiviteHalteA {
		margin-left: 20px;
	}

	.ActiviteDeuxHalteA {
		padding-top: 0px;
		 padding-left: 0px;
		padding-right: 0px; 
	}

	.ActiviteTroisHalteA {
		padding-top: 0px;
	}

	
	.rightInscriptionActiviteAnglais
	{
		margin-right: 22px;
	}
	
	
}