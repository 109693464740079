/*
 nom du fichier : Profile.css
 fonction: ce fichier represente le css de la page profile

 auteur : maxence mazeau.

*/

.grid-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 5px;
}

.grid-left {
  grid-row: 1 / span 2;
  padding: 10px;
}

.grid-right-top {
  padding: 10px;
}

.grid-right-bottom {
  padding: 10px;
}

.input-container {
  margin-top: 40px;
}

.input-container input {
  background-color: #EBF1F6;
  color: black;
  width: 67%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: 0 0 5pt 0.5pt #D3D3D3;
  font-size: 16px;
}

.input-container button {
  background-color: #3D488B;
  color: white;
  padding: 10px;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  width: 100%;
  font-size: 16px;
  margin-top : auto;
}

.input-container button[type="submit"] {
  display: block;
  background-color: #3D488B;
  color: white;
  padding: 10px;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  width: 15%;
  font-size: 16px;
}


.input-container textarea {
  background-color: #EBF1F6;
  color: black;
  height: 100%;
  width: 67%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: 0 0 5pt 0.5pt #D3D3D3;
  margin-top: 5px;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    height: 100%;
  }

  .grid-left {
    grid-row: auto;
  }

  .input-container input {
    width: 100%;
  }

  .input-container button {
    width: 30%;
    margin-left: 0;
  }

  .input-container button[type="submit"] {
    width: 30%;
  }

  .input-container textarea {
    width: 100%;
  }


}

/* For screens larger than 768px */
@media only screen and (min-width: 768px) {
  .grid-container {
    display: grid;
    height: 90vh;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 5px;
  }

  .grid-left {
    grid-row: 1 / span 2;
    padding: 10px;
  }

  .grid-right-top {
    padding: 10px;
  }

  .grid-right-bottom {
    padding: 10px;
  }

  .input-container {
    margin-top: 40px;
  }

  .input-container input {
    width: 67%;
  }

  .input-container button {
    width: 20%;
    margin-left: 10px;
  }

  .input-container button[type="submit"] {
    display: block;
    width: 20%;
  }

  .input-container textarea {
    width: 67%;
    margin-top: 5px;
  }
}



