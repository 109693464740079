/*
 Nom du fichier : Connexion.css
 Fonction: ce fichier représente le css du fichier Connexion.js avec le mode pc, tablette et Téléphone.

 Auteur : Thanina adda.
 Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css
*/

/* Main du fichier */
.mainConnexion {


	background-image: url("/src/components/Connexion/images/image1.jpg");

	background-repeat: no-repeat;
	/* background-size: 100% 125%; */
	background-size: 120% 125%;
	/* background-size: 110% 120%; */
	

}

.titreInterfaceConnexion {
	margin-left: 8%;
}
/* custom-size */
.custom-size {
	width: 100%;
	height: 100vh;
}


/* form-area  */
.form-area {

	padding-left: 20px;
	width: 100%;
	height: auto;
	padding-top: 5%;
	padding-bottom: 16%;
	

}

/* single-form */
.single-form {
	
	background-color: #D0D5E5;
	box-shadow:
		-10px 0px 20px -5px rgba(0, 0, 0, 0.2),
		0px 2px 20px -5px rgba(0, 0, 0, 0.5);

}

/* block gauche du projet */
.left {
	filter: contrast(90%) opacity(0.7);
	background-color: white;
	text-align: justify;
	
	padding: 150px 50px;

}

/* span du block gauche */
.left span {

	font-size: 18px;


}

/* pan du formulaire de Connexion */
.spanFomulaireConnexion {
	font-size: 14px;
	font-weight: 600;
	margin-top: 100px;
	text-align: center;
	cursor: pointer;
	  
}

/*  h3 du block du cote gauche */
.left h3 {
	/* padding-top: 20px; */
	font-family: poppins;
	font-weight: 700;
	color: #000;
	text-shadow: 1px 1px #fff;
	padding-bottom: 30px;
	
}

/* h5 du block du cote gauche  */
.right h5 {
	text-align: center;

}

/* span du cote gauche du projet */
.left span {
	color: #000;
	text-shadow: 1px 1px #fff;
	font-weight: 600;


}

/* Bouton Submit Connexion Utilisateur */
.btnSubmit {
	display: flex;
	justify-content: center;
}

/* Bloc Droit */
.right {
	
	filter: brightness(90%) opacity(0.9);
	padding-top: 10px;
	padding: 0px 100px;


}

/* i du Bloc Droit de l'inscription */
.right i {
	position: absolute;
	font-size: 80px;
	left: -27px;
	top: 40%;
	color: #fff;
	
}


/* Bouton Submit Connexion */
.right button {
	border: none;
	border-radius: 0;
	background: #252525;
	width: 180px;
	color: #fff;
	padding: 15px 0;
	display: inline-block;
	font-size: 16px;
	margin-top: 20px;
	cursor: pointer;
}

.right button:hover {
	background-color: #252525;
}



/*Media query pour le mode telephone */
@media (min-width:800px) and (max-width:1000px) {
	.form-area {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		padding-left: 20px;
	}
	.right {
		padding: 30px 100px;
	}
	.right i {
		top: -52px;
		transform: rotate(90deg);
		left: 50%;
	}

	.mainConnexion {
		background-repeat: no-repeat;
		background-size: 130% 100%;
	}
	.titreInterfaceConnexion {
		margin-left: 0%;
	}


}

@media (max-width:750px) {
	.mainConnexion {
		background-image: url("/src/components/Connexion/images/image3.jpg");
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.left {
		padding: 10px 50px;
		text-align: justify;
	}

	.left h3 {
		text-align: center;
	}

	.left span {
		font-size: 15px;	
	}

	.spanFomulaireConnexion {
		font-size: 15px;
	}
	.right {
		padding: 10px 18px;
	}

	.right i {
		top: -52px;
		transform: rotate(90deg);
		left: 46%;
	}

	.right button {
		width: 150px;
		padding: 12px 0;
	}

	.form-area {
		padding-left: 20px;
	}
	.titreInterfaceConnexion {
		margin-left: 0%;
	}

}