.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px; /* Set a maximum width for better responsiveness */
  display: flex;
  flex-direction: column;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalTitle h2 {
  margin: 0;
}

.modalSeparator {
  height: 2px;
  width: 100%;
  background-color: #ccc;
  margin-bottom: 20px;
}

.close {
  color: #aaaaaa;
  left : 0;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.greenButton {
  background-color: #2ecc71;
}

.redButton {
  background-color: #e74c3c;
}