/*
 Nom du fichier : Navbar.css
 Fonction: ce fichier représente le css du fichier Navbar.js, avec le mode pc, tablette et Téléphone.

 Auteur : Thanina adda.
  Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css
*/

/* App du fichier */
.App {
  width: 100%;
  height: 100%;
  
}
.nav-link {
  cursor: pointer;
}

/* le logo image2 */
.logoimage2 {
  display: none;
}


/*Media query pour le mode Telephone */
@media  (max-width: 1200px) {
  

  .logoimage {
    display: none;
  }
  .home {
    
  }
  
  
  .vr {
    visibility: hidden;
  }

  .logoimage2 {
    display: flex;
    justify-items: end;
    margin-right: 18%;

  }
  .telephone 
  { display: none;
  }
  .facebook 
  { 
  }
  

}

@media  (max-width: 400px) {
  

  .logoimage {
    display: none;
  }
  .home {
   
  }
  
  
  .vr {
    visibility: hidden;
  }

  .logoimage2 {
    display: flex;
    justify-items: end;
    margin-right: 15%;
    
  }
  .telephone 
  { display: none;
  }
  .facebook 
  { 
  }
  

}