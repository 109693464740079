/*
* Nom: Footer.css
* Equipe: Thanina, Maxcence, Florian.
* Date: 2023-04-03
* But: ce fichier represente le css du Pied de Page
*/


/* Footer  */
footer{
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  background-color: black;
}
 
/* Container du footer  */
.containerFooter
{
  position:relative;
}
/* Footer block  */
.footerBlock
{
  display: flex;
  flex-direction: row;
  
  
}
.droitDauteur
  {
   padding-top: 5px;
  }
.img-fluid
{
  width: 28%;
}

/*Media query pour le mode Tablette et Telephone */
@media (min-width:600px) and (max-width:991px) {
	.footerBlock
  {
    display: flex;
    flex-direction: column;
  
    
    
  }
  .containerFooter
  { padding: 30px;
    justify-content: center;
  }
  .footerDroite
  {
    text-align: center;
  }

  .img-fluid
  {
    width: 20%;
  }
  .droitDauteur
  {
   padding-top: 5px;
  }
	
}

/* @media (min-width:200px) and (max-width:500px) {
	.footerBlock
  {
    display: flex;
    flex-direction: column;
  
    
    
  }
  .containerFooter
  { padding: 20px;
    justify-content: center;
  }
  .footerDroite
  {
    text-align: center;
  }

  .img-fluid
  {
    width: 18%;
  }
	
} */

@media (min-width:200px) and (max-width:500px) {
  .footerBlock
  {
    display: flex;
 
  
    
    
  }
  footer{
    width: 100%;
    height: 100%;
    padding-bottom: 2px;
    
  }
   
  .img-fluid
  {
    width: 60%;
    margin-right: 20px;
  }
  .containerFooter
  { padding: 10px;
    justify-content: center;
    font-size: 10px;
  }
  .footerDroite
  {
    text-align: center;
   
  }

 
  .droitDauteur
  {
    font-size: 12px;
    
  }
 
} 