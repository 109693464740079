/*
 Nom du fichier : Activite.js
 Fonction: ce fichier représente le css de la page d'inscription aux activités,
 avec le mode pc, tablette et Téléphone.
  
 Auteur : Thanina adda.
 
*/

/* Bloc Droit de l'inscription Benevole */
.mainActivite {
	width: 100%;
	height: auto;
	padding: 5%;
	padding-bottom: 14%;
	background-color: #EEEEEE;
	display: flex;
	flex-direction: row;


}

.ActiviteDroite {
	box-shadow:
		-10px 0px 20px -5px rgba(0, 0, 0, 0.1),
		0px 2px 20px -5px rgba(0, 0, 0, 0.3);

	padding-bottom: 20px;

	background-color: whitesmoke;
	margin-top: 50px;

}

.ActiviteGauche {
	padding-right: 5%;
	margin-top: 50px;
}

.selected {
	background-color: #FFFFFF;
	color: #3C4E8B;

	border-radius: 50%;
}


.activiteD {
	margin-top: 25px;
	margin-bottom: 25px;
	margin-left: 20px;
}

.activiteG {
	margin-top: 35px;
	cursor: pointer;
}
.h5
{
	margin-left: 20px;
}
.single-formActivite {

	padding-top: 30px;
	background-color: whitesmoke;
	box-shadow:
		-10px 0px 20px -5px rgba(0, 0, 0, 0),
		0px 2px 20px -5px rgba(0, 0, 0, 0.3);
}


.btnSubmitActivite {
	display: flex;
	justify-content: center;
	padding-bottom: 10px;


}

.btnSubmitActivite2 {
	display: flex;
	justify-content: center;
	margin-right: 20px;


}

/* i du Bloc Droit de l'inscription Benevole */
.rightInscriptionActivite i {
	position: absolute;
	font-size: 80px;
	left: -27px;
	top: 40%;
	color: #fff;
}


.rightInscriptionActivite button {
	border: none;
	border-radius: 0;
	background: #252525;
	width: 180px;
	color: #fff;
	padding: 15px 0;
	display: inline-block;
	font-size: 16px;
	margin-top: 20px;
	cursor: pointer;
}
.btnSubmitActivite2 button {
	border: none;
	border-radius: 0;

	width: 180px;
	color: #fff;
	padding: 15px 0;
	display: inline-block;
	font-size: 16px;
	margin-top: 20px;
	cursor: pointer;

}

.rightInscriptionActivite button:hover {
	background-color: #252525;
}


.ListeActivite {
	margin-top: 20px;
}

.activiteBlock4 {

	color: white;
	background-color: #3C4E8B;
}


.activiteBlock4 p {

	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

.ActiviteUne {}

.ActiviteDeux {

	padding-left: 11px;
	padding-right: 10px;
	margin-bottom: 10px;

}

.activiteBlock3 button {
	padding-top: 10px;
}

p {
	text-align: justify;
}

.iconActivite {
	margin-right: 20px;
}

.activiteBlock1 {
	margin: 10px;
}

.activiteBlock2 {}

.activiteBlock3 {
	margin: 10px;
}

.activiteBlock5 {
	margin: 10px;

}

.row {
	width: 100%;
	display: flex;
	justify-content: center;
}

.formulaireActiviteUn {
	padding-bottom: 10px;
	padding-left: 20px;
}

.formulaireActiviteDeux {
	padding-bottom: 10px;
	padding-left: 20px;
}

.formulaireActiviteTrois {
	padding-bottom: 10px;
	padding-left: 20px;
}

.boutonInscriptionAc {
	size: 10px;
}

.selected {
	background-color: #FFFFFF;
	color: #3C4E8B;

	border-radius: 50%;
}

/* Modal Overlay */
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Modal Container */
.modal-container {
	position: relative;
	max-width: 90%;
	max-height: 90%;
	overflow: auto;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	padding: 20px;
}

/* Close Button */
.modal-close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 1.5rem;
	color: #999;
	cursor: pointer;
}

/* Example Usage */
.modal-overlay {
	display: none;
}

.modal-show {
	display: flex;
}
.ActiviteTrois span {
	padding: 5%;
}


@media (min-width:868px) and (max-width:1000px) {
	.mainActivite {
		display: block;
	}

	.single-formActivite {
		width: 100%;
	}

	.single-formActivite1 {
		display: none;
	}

	.selected {
		background-color: #FFFFFF;
		color: #3C4E8B;
		border-radius: 50%;
	}

	.ActiviteGauche {
		padding-right: 0%;
	}

}

@media (max-width:860px) {



	.mainActivite {
		background-color: whitesmoke;
		display: block;
		padding: 10px;

	}

	.single-formActivite1 {
		display: none;
	}

	.single-formActivite {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.ActiviteDeux {
		
		margin-bottom: 10px;
	}

	.single-formActivite1 {
		display: none;
	}

	.ActiviteDeux {
		padding-top: 10px;
	}


	.ActiviteTrois {
		padding-top: 10px;
	}

	.ActiviteGauche {
		padding-right: 0%;
	}

}

@media (max-width:500px) {

	.mainActivite {
		background-color: whitesmoke;
		display: block;

		padding: 10px;

	}

	.single-formActivite1 {
		display: none;
	}

	.single-formActivite {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.ActiviteDeux {
		padding-left: 0px;
		padding-right: 0px;
		margin-bottom: 0px;
	}

	.single-formActivite1 {
		display: none;
	}

	.ActiviteDeux {
		padding-top: 0px;
	}

	.ActiviteTrois {
		padding-top: 10px;
	}

	.ActiviteGauche {
		padding-right: 0%;
	}

}