/*
 nom du fichier : information.css
 fonction: ce fichier represente le css des 2 fichiers InformationsParent et Benevole.

 auteur : maxence mazeau.
 Modification: Thanina Adda, modification au niveau du css

*/


.mainDocumentation {
  background-color: whitesmoke;

  width: 100%;
  height: auto;


}


/* Label du fichier */
label {
  font-weight: 600;
}

/* Form area du fichier inscription Parent*/
.form-areaDocumentation {

  
  margin-left: 20px;
  height: max-content;
  padding-top: 3%;
  padding-bottom: 10%;
}

.fenetre {


  -ms-overflow-style: none;
}



.info {
  font-family: poppins;
  padding: 10px;
  border-radius: 10px;
  
  margin-top: 10px;
  width: 90%;
  background-color: #D0D5E5;
  text-align: left;
  cursor: pointer;
  box-shadow:
    -10px 0px 20px -5px rgba(0, 0, 0, 0.1),
    0px 2px 20px -5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.titre {
  text-align: left;
  font-weight: bold;
}


.description {
  border-radius: 10px;
  text-align: left;
  padding: 5px;
  color: black;
  overflow: hidden;
}

.lien {


  word-wrap: break-word;
  text-align: justify;

  margin-left: auto;
}

h2 {
  margin-left: 20px;
  margin-top: 15px;
}




/* @media (min-width: 768px) and (max-width: 991.98px) {
  .mainDocumentation {
  
    width: 100%;
    height: auto;
  }
  .form-areaDocumentation {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-left: 10px;
  }

  .info {
    padding: 10px;
    
    margin-left: 20px;
    margin-top: 10px;
    width: 60%;
    
    text-align: left;
    cursor: pointer;
    
  }
  
  .titre {
    text-align: left;
    font-weight: bold;
    color : #3D4E8B;
  }
  
  .description {
    margin-top : 3px;
    padding : 1px;
    border-radius: 10px;
    text-align: left;
    color: black;
    overflow: hidden;
  }

  .info {
   
    
    width: 100%;
    padding: 10px;
    
  }
  .Documentation h3 
  {
    font-size: 18px;
  }
} */
  
  @media only screen and (max-width: 768px) {
    .mainDocumentation {
     
    
      width: 100%;
      height: auto;
    
    
    }
    .info {
      
      width: 90%;
    }
  
    .lien {
      left: 5%;
    }
  }
  
  @media only screen and (min-width: 300px) and (max-width: 700px) {
    .info {
      margin-left: 5px;
     
    }
  
    .lien {
      left: 55%;
    }
  }

